<template>
  <div class="grid-default px-page mb-4 mt-10 gap-y-11 md:mb-40 md:mt-30 md:gap-y-0">
    <div
      class="col-span-12"
      :class="[
        {
          'order-2 md:col-span-6 md:col-start-8':
            data.mediaSize === 'Medium' && data.mediaPosition === 'Left',
          'order-2 md:order-1 md:col-span-5 md:col-start-1':
            data.mediaSize === 'Medium' && data.mediaPosition === 'Right',
          'md:order-2 md:col-span-4 md:col-start-9':
            data.mediaSize === 'Large' && data.mediaPosition === 'Left',
          'md:col-span-4 md:col-start-1':
            data.mediaSize === 'Large' && data.mediaPosition === 'Right'
        }
      ]"
    >
      <div class="h-full">
        <Heading
          v-if="data.title"
          :level="1"
          :size="1"
          :class="data.mediaSize === 'Large' ? 'mb-4 md:mb-[2rem]' : 'mb-8 md:mb-[5rem]'"
        >
          <div v-html="data.title"></div>
        </Heading>
        <div
          v-if="data.html"
          class="text-xl [&>p:last-child]:!mb-0 [&>p]:mb-[1.6rem] [&>p]:md:mb-[2.1rem]"
          :class="[
            data.mediaSize === 'Large'
              ? 'mb-6 leading-[1.36] md:mb-[2.9375rem] md:text-[2.125rem] md:tracking-[-0.01em] lg:leading-[1.3]'
              : 'leading-[28px] md:text-body-25-regular'
          ]"
          v-html="data.html"
        ></div>
        <CallToAction
          v-if="Array.isArray(data.buttons) && data.buttons.length"
          :data="data.buttons"
        />
      </div>
    </div>
    <div
      class="col-span-12"
      :class="[
        {
          'order-1  md:col-span-6 md:col-start-1':
            data.mediaSize === 'Medium' && data.mediaPosition === 'Left',
          'order-1 md:order-2 md:col-span-6 md:col-start-7':
            data.mediaSize === 'Medium' && data.mediaPosition === 'Right',
          'md:col-span-7 md:col-start-1':
            data.mediaSize === 'Large' && data.mediaPosition === 'Left',
          'md:col-span-7 md:col-start-6':
            data.mediaSize === 'Large' && data.mediaPosition === 'Right'
        }
      ]"
    >
      <div
        ref="containerRef"
        class="w-[calc(100%+16px)] md:w-[calc(100%+2.5rem)]"
        :class="[
          {
            '-ml-4 md:-ml-10 ': data.mediaSize === 'Medium' && data.mediaPosition === 'Left',
            '-ml-4 md:-mr-10 md:ml-auto': data.mediaPosition === 'Right',
            '-ml-4 md:-ml-10': data.mediaSize === 'Large' && data.mediaPosition === 'Left'
          }
        ]"
      >
        <Picture
          preload
          class="aspect-5/4"
          :clipSection="data.mediaPosition === 'Right' ? 'hero' : 'heroLeft'"
          imgClass="w-full"
          sizes="(min-width: 1000px) 57.5vw, calc(100vw - 16px)"
          :data="data?.media!"
          :width="width"
          :height="height"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SectionHeroRecord } from '~/types/generated'

const containerRef = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(containerRef)

defineProps({
  data: {
    type: Object as PropType<SectionHeroRecord>,
    default: () => {}
  }
})
</script>
